import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Visible from "../Visible";
import TextField from "@material-ui/core/TextField";
import { getUnixTime } from "date-fns";
import {
  updateThenGetSDTs,
  removeThenGetSDTs,
} from "../../actions/maintenance";

// import { RbacContext } from "../../containers/LoadingContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    minWidth: 275,
    marginBottom: theme.spacing(2),
  },
  dateRanges: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginBottom: 5,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  startDate: {
    marginRight: 8,
  },
  endDate: {
    marginLeft: 8,
  },
}));

export default function ConfigureMaintenance({
  row,
  state,
  closeDialog,
  name,
  account_id,
  callback,
}) {
  const [open, setOpen] = useState(state);
  const classes = useStyles();
  const [currentSDT, setSDT] = useState(row);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isChanged, setChanged] = useState(false);
  const [initialStart, setInitialStart] = useState(null);
  const [initialEnd, setInitialEnd] = useState(null);
  const [deviceIdentifier, setDeviceIdentifier] = useState("");
  const devs = useSelector((state) => state.devices.byId);
  const config = useSelector((state) => state.ui_settings.portal_config);
  // const manage = useContext(RbacContext).Maintenance.manage;
  /*const deviceArray = useSelector((state) => state.deviceSearch.Devices);
  const groupArray = useSelector(
    (state) => state.deviceGroups.deviceGroupItems
  ); */
  const dispatch = useDispatch();
  const callbackFromParent = () => callback();
  useEffect(() => {
    if (state) {
      setOpen(state);
      setSDT(row);
      try{
        if ("planned_maintenance_identifier" in config) {
          setDeviceIdentifier(
            devs[row.deviceId][config["planned_maintenance_identifier"]]
          );
        } else {
          setDeviceIdentifier(devs[row.deviceId][config["device_identifier"]]);
        }
      }catch(err){
        setDeviceIdentifier(row.deviceDisplayName)
      }

      setStartTime(formatTime(row.startDateTimeOnLocal));
      setEndTime(formatTime(row.endDateTimeOnLocal));
      setInitialStart(formatTime(row.startDateTimeOnLocal));
      setInitialEnd(formatTime(row.endDateTimeOnLocal));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, row, devs]);

  const formatTime = (time) => {
    return time.split(" ")[0] + "T" + time.split(" ")[1];
  };

  const changeStartDate = (val) => {
    setStartTime(val);
    val += ":00";
    if (val === initialStart && endTime === initialEnd) setChanged(false);
    else setChanged(true);
  };

  const changeEndDate = (val) => {
    setEndTime(val);
    val += ":00";
    if (val === initialEnd && startTime === initialStart) setChanged(false);
    else setChanged(true);
  };

  const handleClose = () => {
    setOpen(false);
    setChanged(false);
    closeDialog();
    setSDT({
      id: "-",
      start: "-",
      end: "-",
      duration: "-",
      type: "-",
      appliedAt: "-",
      admin: "-",
      timezone: "-",
    });
  };

  const submitChange = () => {
    var start = getUnixTime(new Date(startTime)) * 1000;
    var end = getUnixTime(new Date(endTime)) * 1000;

    dispatch(
      updateThenGetSDTs(account_id, row.id, deviceIdentifier, start, end)
    );
    setTimeout(() => callbackFromParent(), 300);
    handleClose();
  };

  const submitRemoval = () => {
    dispatch(removeThenGetSDTs(account_id, row.id, deviceIdentifier));
    setTimeout(() => callbackFromParent(), 300);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Maintenance Summary: {name}
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <Card elevation={2} className={classes.root}>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="primary"
                  style={{ marginBottom: "20px" }}
                >
                  Description
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ marginBottom: "10px" }}
                >
                  {currentSDT.admin ? currentSDT.admin : "no message in alert"}
                </Typography>
                <Typography variant="body2" component="p">
                  {currentSDT.comment
                    ? currentSDT.comment
                    : "No comment left by admin."}
                </Typography>
              </CardContent>
            </Card>
          </div>
          <Visible permissionNeeded="maintenance" capabilityNeeded="manage">
            <div className={classes.dateRanges}>
              <TextField
                id="datetime-start"
                label="Start Time"
                variant="outlined"
                fullWidth
                // disabled={!manage}
                type="datetime-local" //{manage ? "datetime-local" : "text"}
                defaultValue={startTime} // {manage ? startTime : startTime.replace("T", " ")}
                className={classes.startDate}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => changeStartDate(e.target.value)}
              />
              <TextField
                id="datetime-end"
                label="End Time"
                variant="outlined"
                fullWidth
                //disabled={!manage}
                type="datetime-local" //{manage ? "datetime-local" : "text"}
                defaultValue={endTime} //{manage ? endTime : endTime.replace("T", " ")}
                className={classes.endDate}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => changeEndDate(e.target.value)}
              />
            </div>
          </Visible>
        </DialogContent>
        <DialogActions>
          <Visible permissionNeeded="maintenance" capabilityNeeded="manage">
            <Button
              onClick={submitRemoval}
              color="primary"
              variant="contained"
              disableElevation
            >
              Remove SDT
            </Button>
            <Button
              onClick={submitChange}
              color="primary"
              variant="contained"
              disableElevation
              disabled={!isChanged}
            >
              Submit Change
            </Button>
          </Visible>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
