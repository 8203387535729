import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { removeProperty, updateProperty } from "../../actions/devices";
import ProviderDropdown from "../ProviderDropdown";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
  },
  formControl: {
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  field: {
    width: theme.spacing(20),
    height: 20,
  },
}));

export default function EditDeviceProperty({
  state,
  closeDialog,
  id,
  billing_ref,
  title,
  prop,
  existing,
  custom,
  providerList,
}) {
  const [open, setOpen] = React.useState(state);
  const [newValue, setNewValue] = React.useState("");
  const [running, setRunning] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [oldValue, setOldValue] = React.useState("");
  const [unit, setUnit] = React.useState("Mb");
  const classes = useStyles();
  const email = useSelector((state) => state.userSession.email) || "";
  const account_id = useSelector(
    (state) => state.userSession.properties.account_id
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (state) {
      setOpen(state);
      setOldValue(existing);
    }
  }, [state, existing]);

  const handleClose = () => {
    setRunning(false);
    setOpen(false);
    setNewValue("");
    closeDialog();
    setUnit("Mb");
    setMessage("");
    setStatus("");
  };

  const dispatchUpdateProperty = () => {
    setRunning(true);
    dispatch({ type: "REQUEST_UPDATE_CUSTOM_PROPERTY" });
    const payload = {
      id: id,
      billing_ref: billing_ref,
      user: email,
      account_id: account_id,
      custom: custom,
      properties: [
        {
          name: prop,
          value:
            title === "Circuit Size" || title === "Bearer Size"
              ? newValue + unit
              : newValue,
        },
      ],
    };

    updateProperty(payload)
      .then((res) => {
        dispatch({
          type: "SUCCESS_UPDATE_CUSTOM_PROPERTY",
          meta: {
            id: payload.id,
            name: payload.properties[0].name, // temp change
            value: payload.properties[0].value,
            custom: payload.custom,
          },
        });
        try {
          if (res.message.includes("all properties"))
            res.message = res.message.replace("all properties", "property");
          setStatus(res.status_code);
          setMessage(res.message);
          setOldValue(newValue);
          setNewValue("");
        } catch (e) {
          console.log(e);
        }
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: "FAILURE_UPDATE_CUSTOM_PROPERTY" });
      })
      .finally(() => setRunning(false));
  };

  const dispatchRemoveProperty = () => {
    setRunning(true);
    dispatch({ type: "REQUEST_REMOVE_CUSTOM_PROPERTY" });
    const payload = {
      id: id,
      prop: prop,
    };
    removeProperty(payload)
      .then((res) => {
        dispatch({
          type: "SUCCESS_REMOVE_CUSTOM_PROPERTY",
          meta: payload,
        });
        setStatus(res.status_code);
        setMessage(res.message);
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: "FAILURE_REMOVE_CUSTOM_PROPERTY" });
      })
      .finally(() => handleClose());
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Change {title} Value</DialogTitle>
      <DialogContent>
        <Grid container style={{ width: "720px" }} spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Current Value"
              variant="outlined"
              value={oldValue}
              className={classes.fieldd}
              maxRows={3}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            {title === "Provider Name" ? (
              <ProviderDropdown
                options={providerList || []}
                onChange={(_, v) => {
                  setNewValue(v.name);
                }}
              />
            ) : (
              <TextField
                label="New Value"
                variant="outlined"
                value={newValue}
                onChange={(e) =>
                  title === "Circuit Size" || title === "Bearer Size"
                    ? e.target.value.match(/[0-9]+/) || !e.target.value
                      ? setNewValue(e.target.value)
                      : null
                    : setNewValue(e.target.value)
                }
                className={`${classes.fieldd} ${
                  (title === "Circuit Size" || title === "Bearer Size") &&
                  classes.root
                }`}
                maxRows={1}
                focused
                fullWidth
                InputProps={
                  (title === "Circuit Size" || title === "Bearer Size") && {
                    endAdornment: (
                      <Select
                        onChange={(e) => setUnit(e.target.value)}
                        value={unit}
                      >
                        <MenuItem value="Mb">Mb</MenuItem>
                        <MenuItem value="Gb">Gb</MenuItem>
                      </Select>
                    ),
                  }
                }
              />
            )}
          </Grid>
        </Grid>
        <div>
          {status && message && (
            <Alert severity={status === 200 ? "success" : "error"}>
              {status}: {message}
            </Alert>
          )}
        </div>
      </DialogContent>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: 24, paddingTop: 0, paddingBottom: 20 }}
      >
        <Grid item>
          {custom && (
            <Button
              onClick={dispatchRemoveProperty}
              color="primary"
              variant="contained"
              disabled={running}
            >
              Remove
            </Button>
          )}
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              {running ? <CircularProgress style={{ bottom: 0 }} /> : null}
            </Grid>
            <Grid item>
              <Button
                style={{ marginLeft: 5 }}
                onClick={dispatchUpdateProperty}
                color="primary"
                disabled={running || !newValue}
                variant="contained"
              >
                Confirm
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{ marginLeft: 5 }}
                onClick={handleClose}
                disabled={running}
                variant="contained"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
