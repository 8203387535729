import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CAPABILITY_LEVELS } from "../constants";

let defaultDevicePermissions = {
  alerts: CAPABILITY_LEVELS["none"],
  customProperties: CAPABILITY_LEVELS["none"],
  diagnostics: CAPABILITY_LEVELS["none"],
  metrics: CAPABILITY_LEVELS["none"],
  raiseTicket: CAPABILITY_LEVELS["none"],
  system: CAPABILITY_LEVELS["none"],
  systemProperties: CAPABILITY_LEVELS["none"],
};

const useRelevantPermissions = () => {
  /*
    Filter permissions for the device group that user is current viewing.
  */
  let rootFolderId = 9841;

  const deviceId = parseInt(useParams()?.id);

  const Device = useSelector((state) => state.devices.byId[deviceId]);

  const permissions = useSelector((state) => state.roles.permissions);

  const parent = useSelector(
    (state) => state.deviceGroups.deviceGroupItems.parent
  );

  if (!deviceId) {
    return { ...permissions, services: defaultDevicePermissions };
  }

  let { parentId } = Device;

  const rootPerms = permissions.services.find((p) => p.id === rootFolderId);

  const customerPerms = permissions.services.find((p) => p.id === parentId);

  const subGroupPerms = parent.subGroups
    ? permissions?.services.find((p) =>
        parent.subGroups.find((sg) => sg.id === parentId)
      )
    : null;

  const permissionKeys = Object.keys(defaultDevicePermissions);

  let relevantPermissions = {};

  permissionKeys.forEach((key) => {
    let rootPerm = rootPerms ? rootPerms[key] : CAPABILITY_LEVELS["none"];
    let customerPerm = customerPerms
      ? customerPerms[key]
      : CAPABILITY_LEVELS["none"];
    let subGroupPerm = subGroupPerms
      ? subGroupPerms[key]
      : CAPABILITY_LEVELS["none"];
    relevantPermissions[key] = Math.min(rootPerm, customerPerm, subGroupPerm);
  });

  return { ...permissions, services: relevantPermissions };
};

export default useRelevantPermissions;
