import {
  createStyles,
  Divider,
  IconButton,
  Tab,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import { DateRange, Note } from "@material-ui/icons";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { getUnixTime } from "date-fns";
import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import ContainerHeader from "../components/ContainerHeader";
import { LinkDevices } from "../components/dialogs/LinkDevices";
import SubmitTicket from "../components/dialogs/SubmitTicket";
import TimeRangeDialog from "../components/dialogs/TimeRangeDialog";
import PageRefreshInput from "../components/PageRefreshInput";
import Visible from "../components/Visible";
import DeviceContainer from "./DeviceContainer";

const styles = (theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(1),
      flexGrow: 1,
      "& .MuiBox-root": {
        paddingTop: 20,
      },
    },
  });

class CircuitsContainer extends Component {
  constructor(props) {
    super(props);
    var today = new Date();
    var yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    var device = this.props.device;
    var tab = 0;
    if (device && device.Links) {
      device.Links.forEach((link, i) =>
        device.id === link.id ? (tab = i) : null
      );
    }
    this.state = {
      tab: tab,
      device_1: {},
      device_2: {},
      timeRangeDialogVisible: false,
      rangesChanged: false,
      fromDate: yesterday,
      fromEpoch: getUnixTime(yesterday),
      toDate: today,
      toEpoch: getUnixTime(today),
      dateType: "24 Hours",
      device_1_update: false,
      device_2_update: false,
      linkDialogState: false,
      active_device: device && device.id,
    };
  }

  handleTimeRangeClosure = () => {
    this.setState({
      timeRangeDialogVisible: false,
    });
  };

  setTimeRangeDialogVisible = (state) => {
    this.setState({
      timeRangeDialogVisible: state,
    });
  };

  setStartDate = (date) => {
    var hasChanged = false;
    if (this.state.fromEpoch !== getUnixTime(date)) {
      hasChanged = true;
    }
    this.setState({
      rangesChanged: hasChanged,
      fromDate: date,
      fromEpoch: getUnixTime(date),
    });
  };

  setDateType = (type) => {
    this.setState({
      dateType: type.replaceAll("_", " "),
    });
  };

  setEndDate = (date) => {
    var hasChanged = false;
    if (this.state.fromEpoch !== getUnixTime(date)) {
      hasChanged = true;
    }
    this.setState({
      rangesChanged: hasChanged,
      toDate: date,
      toEpoch: getUnixTime(date),
    });
  };

  handleDataRangeOpen = (state) => {
    this.setState({
      timeRangeDialogVisible: true,
    });
  };

  handleTicketClosure = () => {
    this.setState({
      submitState: false,
    });
  };

  handleTicketButtonClick = () => {
    this.setState({
      submitState: true,
    });
  };

  handleLinkDialog = (state) => {
    this.setState({
      linkDialogState: state,
    });
  };

  render() {
    const {
      tab,
      fromEpoch,
      toEpoch,
      dateType,
      rangesChanged,
      device_1_update,
      active_device,
      timeRangeDialogVisible,
      fromDate,
      toDate,
      submitState,
    } = this.state;
    const { device, byId, links, device_page_title, classes } = this.props;
    const menuItems = [
      "Today",
      "24 Hours",
      "Week",
      "30 Days",
      "This Calendar Month",
      "Last Calendar Month",
      "Year",
      "Custom",
    ];

    if (!device) return null;
    return (
      <div className={classes.root}>
        <TabContext value={tab}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: !links ? 10 : 0,
            }}
          >
            {links !== undefined ? (
              <div style={{ display: "flex" }}>
                <ContainerHeader custom={"Back To Inventory"} />
                <TabList
                  onChange={(_, newTab) => this.setState({ tab: newTab })}
                  style={{ marginBottom: 20 }}
                >
                  {links.map((link, i) => (
                    <Tab
                      label={link.tag}
                      value={i}
                      onClick={() => this.setState({ active_device: link.id })}
                    />
                  ))}
                </TabList>
              </div>
            ) : (
              <ContainerHeader
                title={device[device_page_title]}
                custom={"Back To Inventory"}
              />
            )}
            <TimeRangeDialog
              visible={timeRangeDialogVisible}
              callbackClose={() => this.handleTimeRangeClosure()}
              callbackOpen={() => this.setTimeRangeDialogVisible(true)}
              setStartDate={(date) => this.setStartDate(date)}
              setEndDate={(date) => this.setEndDate(date)}
              setDateType={(date) => this.setDateType(date)}
              startDate={fromDate}
              endDate={toDate}
              menuItems={menuItems}
              disabled={false}
              extraConfig={false}
              dialogTitle={"Date Range"}
              timeRangeDisabled={false}
            />
            <SubmitTicket
              state={submitState}
              account={this.account}
              device={byId[active_device]}
              callbackFunction={() => this.handleTicketClosure()}
              // refreshTicketList={() => callback()}
            />

            <div style={{ display: "flex", alignItems: "center" }}>
              <PageRefreshInput
                disabled={rangesChanged}
                refreshFunction={() => {
                  this.setState({
                    ...(tab
                      ? { device_2_update: true }
                      : { device_1_update: true }),
                  });
                }}
              />
              <Typography
                style={{ display: "inline-block" }}
                color="textSecondary"
                //gutterBottom
              >
                {dateType}
              </Typography>
              <Tooltip disableFocusListener title="Select Date Range">
                <IconButton onClick={() => this.handleDataRangeOpen()}>
                  <DateRange color="primary" />
                </IconButton>
              </Tooltip>
              <Visible
                component={"TicketInfoButton"}
                permissionNeeded="services.raiseTicket"
                capabilityNeeded="manage"
              >
                <Tooltip disableFocusListener title="Raise Ticket">
                  <IconButton
                    onClick={() => this.handleTicketButtonClick(true)}
                  >
                    <Note color="primary" />
                  </IconButton>
                </Tooltip>
              </Visible>
              <LinkDevices device={device} />
            </div>
          </div>
          <Divider />
          {links !== undefined ? (
            links.map((link, i) => (
              <TabPanel value={i} style={{ padding: 0 }}>
                <DeviceContainer
                  fromEpoch={fromEpoch}
                  toEpoch={toEpoch}
                  dateType={dateType}
                  device={byId[link.id]}
                  rangesChanged={rangesChanged}
                  forceUpdate={device_1_update}
                  completeUpdate={() =>
                    this.setState({ device_1_update: false })
                  }
                />
              </TabPanel>
            ))
          ) : (
            <DeviceContainer
              fromEpoch={fromEpoch}
              toEpoch={toEpoch}
              dateType={dateType}
              device={device}
              rangesChanged={rangesChanged}
              forceUpdate={device_1_update}
              completeUpdate={() => this.setState({ device_1_update: false })}
            />
          )}
        </TabContext>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { byId } = state.devices;
  const device = byId && byId[ownProps.match.params.id];
  const links = device && device.Links;
  const { device_page_title } = state.ui_settings.portal_config;
  return { device, byId, links, device_page_title };
}

const enhance = compose(withStyles(styles), connect(mapStateToProps));

export default enhance(CircuitsContainer);
