import { epochToTimestampWithoutTime } from "../libs/utils";
const severityMap = {
  4: "Critical",
  3: "Error",
  2: "Warning",
};

export function devices(
  state = {
    byId: null,
    loading: false,
    deviceProperties: { byGroup: null, perProp: null },
  },
  action
) {
  switch (action.type) {
    case "ALERTS_SUCCESS":
      var newPayload = filterAllAlerts(action.payload, action.meta);
      return Object.assign({}, state, {
        byId: mapAlertToDevice(state.byId, newPayload),
      });
    case "RECEIVE_DEVICE":
      return Object.assign({}, state, {
        loading: false,
        byId: action.payload,
        // byId: action.payload.data.items
      });
    case "REQUEST_DEVICE":
      return Object.assign({}, state, {
        // byId: [],
        loading: true,
      });
    case "DEVICES_SUCCESS":
      return Object.assign({}, state, {
        devices: action.payload,
        deviceProperties: {
          ...state.deviceProperties,
          ...deviceProperties(
            action.payload,
            state.deviceProperties.config_props
          ),
        },
        byId: !state.byId
          ? keyDevicesById(action.payload)
          : {
              ...state.byId,
              ...keyDevicesById(action.payload, state.byId),
            },
      });
    case "RECEIVE_DEVICE_UPTIME":
      return Object.assign({}, state, {
        byId: mapUptimeToDevice(state.byId, action.payload, action.meta.id),
      });
    case "SUCCESS_ADD_CUSTOM_PROPERTY":
      return Object.assign({}, state, {
        byId: addCustomProperty(state.byId, action.meta),
      });
    case "SUCCESS_UPDATE_CUSTOM_PROPERTY":
      return Object.assign({}, state, {
        byId: updateProperty(state.byId, action.meta),
      });
    case "SUCCESS_REMOVE_CUSTOM_PROPERTY":
      return Object.assign({}, state, {
        byId: removeCustomProperty(
          state.byId,
          action.meta.id,
          action.meta.prop
        ),
      });
    case "UPDATE_DEVICE_LINKS":
      return Object.assign({}, state, {
        byId: updateById(state.byId, action.payload),
        devices: updateDevices(state.devices, action.payload),
      });
    case "PORTAL_CONFIGURATION":
      return Object.assign({}, state, {
        deviceProperties: {
          ...state.deviceProperties,
          ...{ config_props: action.payload.device_properties },
        },
      });

    default:
      return state;
  }
}

export function deviceSearch(
  state = {
    deviceIds: null,
    search_term: null,
    api_failure: false,
    loading: false,
  },
  action
) {
  switch (action.type) {
    case "DEVICE_SEARCH_RESET":
      return Object.assign({}, state, {
        loading: false,
        search_term: null,
        deviceIds: [],
      });
    case "DEVICE_SEARCH_REQUEST":
      return Object.assign({}, state, {
        search_term: action.meta ? action.meta.search_term : "",
      });
    case "DEVICES_SUCCESS":
      return Object.assign({}, state, {
        loading: false,
        Devices: action.payload,
        receivedAt: Math.floor(new Date() / 1000),
        api_failure: false,
      });
    case "DEVICE_SEARCH_FAILURE":
      return Object.assign({}, state, {
        api_failure: true,
        loading: false,
      });
    default:
      return state;
  }
}

function updateById(byId, links) {
  let additions = links.additions,
    removals = links.removals;
  additions.forEach(({ id }) => (byId[id].Links = additions));
  removals.forEach((id) => delete byId[id].Links);
  return byId;
}

function updateDevices(devices, links) {
  let additions = links.additions,
    removals = links.removals,
    updated = [...devices];
  devices.forEach((device, i) => {
    if (removals.includes(device.id)) return delete updated[i].Links;
    additions.forEach(({ id }) =>
      device.id === id ? (updated[i].Links = additions) : null
    );
  });
  // for (let j = 0; j < add.length; j++) {
  //   if (add[j].id === devices[i].id) devices[i].Links = add;
  // }
  // for (let j=0; j<remove.length; j++) {
  //   if (remove[j] === devices[i].id) delete devices[i].Links
  // }
  // }

  return updated;
}

function addCustomProperty(byId, meta) {
  if (byId[meta.id].Custom)
    byId[meta.id].Custom.push({ name: meta.name, value: meta.value });
  else byId[meta.id].Custom = [{ name: meta.name, value: meta.value }];
  return byId;
}

function updateProperty(byId, meta) {
  if (meta.custom)
    byId[meta.id].Custom = byId[meta.id].Custom.map((prop) =>
      prop.name === meta.name ? { name: prop.name, value: meta.value } : prop
    );
  else byId[meta.id][meta.name] = meta.value;
  return byId;
}

function removeCustomProperty(byId, id, propName) {
  byId[id].Custom = byId[id].Custom.filter((prop) => prop.name !== propName);
  return byId;
}

function filterAllAlerts(alerts, accountId) {
  var newPayload = [];

  for (var i = 0; i < alerts.length; i++) {
    // console.log(severityMap[alerts[i].severity]);
    alerts[i].severityLabel = severityMap[alerts[i].severity];
    if (alerts[i].rule !== "PingLossPercent Emails") {
      newPayload.push(alerts[i]);
    }
  }

  return newPayload;
}

function mapAlertToDevice(devices, alerts) {
  if (!devices) return;
  var acked, device;
  alerts.forEach((alert) => {
    if (alert.acked) acked = "confirmed";
    else acked = "unconfirmed";
    devices[alert.monitorObjectId] &&
      (devices[alert.monitorObjectId]["Alert Status"] =
        acked + "-" + alert.severityLabel.toLowerCase() + "-none");
    // : console.log("no device for " + alert.monitorObjectId);
  });

  Object.keys(devices).map((id) => {
    device = devices[id];
    device.id = parseInt(device.id);
    if (typeof device["Alert Status"] === "undefined")
      device["Alert Status"] = "none";
    return device;
  });

  return devices;
}

/*function deviceSites(Devices) {
  var mappings = {};
  Devices.forEach((Device) => {
    var manual = JSON.parse(Device.manual);
    if (!manual) return;
    if (!(manual["Customer"] in mappings)) {
      mappings[manual["Customer"]] = [Device.id];
    } else {
      mappings[manual["Customer"]].push(Device.id);
    }
  });
  var output = {};
  Object.keys(mappings)
    .sort()
    .forEach(function (v, i) {
      output[v] = mappings[v];
    });
  return output;
}*/

function deviceProperties(Devices, propGroups) {
  var properties = [];
  Devices.forEach((Device) => {
    var manual = Object.keys(JSON.parse(Device.manual));
    var system = Object.keys(JSON.parse(Device.system));
    var custom;
    try {
      custom = Object.keys(JSON.parse(Device.custom));
    } catch (e) {
      custom = [];
    }
    if (!manual && !system) return;
    for (const element of manual) {
      if (!properties.includes(element)) {
        properties.push(element);
      }
    }
    for (const element of system) {
      if (!properties.includes(element)) {
        properties.push(element);
      }
    }
    for (const element of custom) {
      if (!properties.includes(element)) {
        properties.push(element);
      }
    }
  });
  let propMap = {
    Customer: [],
    Circuit: [],
    Device: [],
    Network: [],
    Custom: [],
  };
  let propArray = [];
  // var propGroups = {
  //   Customer: [
  //     "Account ID",
  //     "Billing Reference",
  //     "Customer",
  //     "Postcode",
  //     "Address",
  //     "Reseller",
  //   ],
  //   Circuit: [
  //     "Provider Name",
  //     "Circuit Reference Provider",
  //     "Circuit Reference",
  //     "Circuit Type",
  //     "Circuit Size",
  //     "Bearer Reference",
  //     "Bearer Size",
  //     "Distribution Router",
  //     "Distribution Interface",
  //     "Distribution",
  //   ],
  //   Device: [
  //     "Date Added",
  //     "Description",
  //     "Chassis Model",
  //     "Hardware",
  //     "DNS Name",
  //     "IP Range",
  //     "Gateway IP",
  //     "Netmask",
  //     "Device Type",
  //   ],
  //   Network: [
  //     "Domain Controller",
  //     "Loopback IP",
  //     "First IP",
  //     "Last IP",
  //     "DNS IP 1",
  //     "DNS IP 2",
  //     "VLAN",
  //   ],
  // };
  const groups = Object.keys(propGroups);
  for (let i = 0; i < properties.length; i++) {
    let custom = true;
    for (let j = 0; j < groups.length; j++) {
      if (propGroups[groups[j]].includes(properties[i])) {
        propMap[groups[j]].push(properties[i]);
        propArray.push({ group: groups[j], name: properties[i] });
        custom = false;
        break;
      }
    }
    if (custom) {
      propMap.Custom.push(properties[i]);
      propArray.push({ group: "Custom", name: properties[i] });
    }
  }
  properties.push("Status");
  propArray.push({ group: "Customer", name: "Account ID" });
  return { byGroup: propMap, perProp: propArray, allProperties: properties };
}

function keyDevicesById(Devices, prevState) {
  var mappings = {};
  Devices.forEach((Device) => {
    let sysProps = JSON.parse(Device.system);
    let manProps = JSON.parse(Device.manual);
    if (!sysProps || !manProps) return;

    Object.keys(sysProps).forEach((key) => {
      if (key === "Date Added") {
        Device[key] = epochToTimestampWithoutTime(parseInt(sysProps[key]));
      } else Device[key] = sysProps[key];
    });
    Object.keys(manProps).forEach((key) => {
      Device[key] = manProps[key];
    });

    if (Device.custom) {
      let custProps = JSON.parse(Device.custom);
      Device.Custom = Object.keys(custProps).map((key) => {
        Device[key] = custProps[key];
        return {
          name: key,
          value: custProps[key],
        };
      });
      delete Device.custom;
    }

    if (prevState) {
      Device["Alert Status"] = prevState[Device.id]["Alert Status"];
      Device["Device Uptime"] = prevState[Device.id]["Device Uptime"];
    }
    /*   if (!Device.hardware) {
      if (Device.chassis_model) {
        Device.hardware = Device.chassis_model;
      } else if (Device.device_type) {
        Device.hardware = Device.device_type;
      }
    }
*/
    Device["Account ID"] = Device.account_id;
    delete Device.account_id;
    delete Device.system;
    delete Device.manual;

    mappings[Device.id] = Device;
  });
  return mappings;
}

function mapUptimeToDevice(byId, uptime, id) {
  byId[id]["Device Uptime"] = uptime;
  return byId;
}
